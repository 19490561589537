/*################################################
################ Header ########################
###############################################*/


h1 {
  color: #ff0084;
}
.carousel-item{
  height: 80vh;
}