/*################################################
################ Navbar ########################
###############################################*/

.navbar{
  background-color: $gregpurple;
  opacity: 0.8;
  a {
    color: white;
  }
  .navContent{
    margin: 0 auto;
    .navbrand{
      background-color: #0d6aad;
    }
    .navlogin{
      background-color: black;
      height: 30px;
    }
    .navnav{
      height: 30px;
    }
  }
}

.shrink {
  background-color: black;
  .navContent{

    min-width: 500px;
    position: relative;
    color: white;
    #navbarNavDropdown{
      position:absolute;
      top: 2px;
      left: 106px;
    }
  }
}
