/*################################################
################ Footer ########################
###############################################*/

footer{
  background-color: $navyblue;
  color: white;
  a{
    color: white;
  }
}