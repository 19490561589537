/*#######################################
############# DEV CSS CLASS #############
#######################################*/

.totored{
  background-color: $rustyred ;
}

.totoblue{
  background-color: cornflowerblue;
}

.totogreen{
  background-color: seagreen;
}

.totopurple{
  background-color: mediumpurple;
}
.plopy{}