@import "~bootstrap/scss/bootstrap";
/* Custom Bootstrap */
@import "bootstrap_custom";
/* Variables */
@import "variables";
/* Core Rules */
@import "main";
/* header */
@import "header";
/* navbar */
@import "navbar";
/*index*/
@import "index";
/* footer */
@import "footer";

/* dev zonning */
@import "dev";

body {
  background-color: whitesmoke;
}
