/* Website colors */

$cloud     : #98878F;
$evening   : #192231;
$gunmetal  : #233237;
$stormy    : #494E68;
$sunset    : #985E6D;
$navyblue  : #18121E;
$rustyred  : #984B43;
$warmyellow: #EAC67A;
$gregpurple: #890EFF;