/*################################################
################ index ########################
###############################################*/

.postlist {
  /*background-color: $gunmetal;*/
  .section_title{
    margin-top: 3%;
    text-align: center;
  }
}
.indextop{
  text-align: center;
}
.idx-contact-bloc{
  h4{
    text-align: left;
  }
}
/*################ Services card ##################*/

.service-card{
  height: 216px;
  position: relative;
  text-align: center;
    .service-img{
      height: 100px;
      margin-bottom: 25px;
      img{
        margin: auto;
        vertical-align: middle;
        width: 40%;
      }
    }
}
.mini-bot {
  background-color: $gregpurple;
  color: white;
}

/*################# Post Vcard ###################*/

.column {
  /*padding: 0 25px;*/
}

.post-card {
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  min-width: 270px;
  max-width: 370px;
  height: 470px;
  overflow: hidden;
}

.post-card:hover{
  -webkit-box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}

.post-card:hover .post-img img
{
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  opacity: .6;
  transition: all 500ms ease-in-out;
}

.post-img{
  /*height: 500px;*/
  overflow: hidden;
}

.post-card img {
  display: block;
  width: 135%;
  height: 371px;
  transition: all 500ms ease-in-out;

}

.date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  background: $gregpurple;
  width: 55px;
  height: 55px;
  border-radius: 100%;
  color: #FFFFFF;
  text-align: center;
  padding: 12.5px 0;
  opacity: 1;
  .day{
    margin-top: -10px;
    font-size: 18px;
  }
  .month{
    font-size: 12px;
    text-transform: uppercase;
  }
}

.post-content {
  position: absolute;
  bottom: 0;
  background: #FFFFFF;
  width: 100%;
  padding: 30px;
}

.category {
  position: absolute;
  top: -40.7px;
  left: 0;
  background: $gregpurple;
  padding: 10px 15px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 26px;
  font-weight: 700;
}

.sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}

.description {
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
  display: none;
}

.up-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}

.post-meta {
  /*margin: 30px 0 0;*/
  color: #999999;
}

.timestamp {
  margin: 0 16px 0 0;
}

.post-meta a {
  color: #999999;
  text-decoration: none;
}




/*################# Offer-card ###################*/

.offer-card{
  box-sizing: content-box;
  height: 500px;
  overflow: hidden;
  position: relative;
  .offer-top{
    height: 40%;
    position: relative;
    text-align: center;
    width: 100%;
    background: #0d6aad url("web/images/dark-leather.png");
    background-size: 100%;
    .offer-topText{
      position:absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
    }
  }
  .offer-bot{
    height: 60%;
    .offer-mid{
      height: 50px;
      position: absolute;
      opacity: 0.7;
      text-align: center;
      font-size: 1.5em;
      width: 80%;
      top: 175px;
      left: 10%;
      p{
        padding-top: 5px;
      }
    }
    .offer-content {
      padding-top: 40px;
    }
  }
}

/*############## Testimony Slider ############# */

.parallax-section{
  -webkit-background-size: cover;
  background-size: cover;
  Background: fixed no-repeat;
  position: relative;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#testimonial{
  padding: 130px 0 95px;
  .overlay{
    background: whitesmoke;
  }
  .container{
    overflow-x: hidden;
    .owl-stage-outer{
      overflow: hidden;
    }
    .owl-stage{
      display: flex;
      .owl-item{
        .active {
          width: 900px;
        }
      }
    }
  }
}
.owl-nav, .owl-dots{
  display: none;
}
.ts-testimonial-slide{
  text-align: center;
  color: black; //todo: changer la couleur du text une fois le slider fini
  width: 85%;
  margin: 0 auto;
  p{
    font-size: 24px;
    line-height: 36px;
    font-style: italic;
    font-family: 'Droid Serif';
    margin-bottom: 35px;
  }
  .ts-testimonial-item{
    margin-bottom: 15px;
    width: 900px;
    .infor-client{
      display: inline-block;
      margin: 0 auto;
      width: auto;
    }
    .icon-client{
      background: black;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      float:left;
      text-align: center;
      line-height: 29px;
      margin-top: 2px;
    }
    .client-name{
      padding-left: 38px;
      font-size: 12.8px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: 'Montserrat';
      display: block;
      line-height: normal;
      white-space: nowrap;
    }
    .client-position{
      font-style: italic;
      line-height: normal;
      padding-left: 38px;
      display: block;
      text-align: left;
    }
    a{
      color: $gregpurple;
      &:hover{
        color: #5ed9e7;
      }
    }
  }

}