/*################################################
################ Main Rules #####################
###############################################*/

.top-buffer{
  margin-top: 1em;
}

.botLow-buffer{
  margin-bottom: 2em;
}

.bot-buffer{
  margin-bottom: 11em;
}

.mini-bot{
  margin-bottom: 15px;
}

.shady-separator{
  height: 6px;
  background-image: radial-gradient(
                  closest-side,
                  hsla(0, 0%, 50%, 1.0),
                  hsla(0, 0%, 50%, 0) 100%);
  margin-top: 0;
  position: relative;
  &:after{
    position: absolute;
    top:  50%;
    left: 50%;
    display:block;
    background-color: hsl(0, 0%, 75%);
    height: 12px;
    width:  12px;
    transform: rotate(45deg);
    margin-top:  -10px;
    margin-left: -10px;
    border-radius: 4px 0;
    border: 4px solid hsla(0, 0%, 100%, 0.35);
    background-clip: padding-box;
    box-shadow: -10px 10px 0 hsla(0, 0%, 100%, 0.15), 10px -10px 0 hsla(0, 0%, 100%, 0.15);
  }
}

/*################ Product card ##################*/

.product-card{
  background-color: $stormy;
  box-sizing: content-box;
  overflow: hidden;
  height: 371px;
  min-width: 270px;
  max-width: 370px;
  position: relative;
  &:hover{
    -webkit-box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
    .product-img img{
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      transform: scale(1.1);
      opacity: .6;
      transition: all 500ms ease-in-out;
    }
    .product-content .description{}
  }
  img{

    height: 60%;
    width: 100%;
  }
  .category{
    width: 35%;
  }
  .product-content{
    background-color: whitesmoke;
    bottom: 0;
    padding: 30px;
    position: absolute;
    transition: all 500ms ease-in-out;
    width: 100%;
    .description{
      display: none;
    }
  }
}

.leftnavBlock{
  background-color: gainsboro;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.blogIndex{
  margin-top: 30px;
  margin-left: 15px;
}
article footer{
  background-color: inherit;
  color: inherit;
}

.postFrame{
  max-height: 750px;
  box-sizing: content-box;
}
.postContent{
  margin-top: 50px;
  margin-bottom: 50px;
}

.loginBox{
  border-radius: 5px;
  min-height: 73vh;
  padding: 15px;
}

.itemContainer{
  padding-top: 30px;

}
.cartcontainer{
  min-height: 59vh ;
}
.receiptCon{
  min-height: 70vh;
}

.parklist{
  min-height: 41.5vh;
}